import { PatronConcents } from "../client/interfaces";

export const mockConsents: PatronConcents = {
  available: [
    {
      type: "loan_history",
      version: "1.0",
    },
  ],
  evaluated: [
    {
      given: true,
      type: "loan_history",
      version: "1.0",
    },
  ],
};
