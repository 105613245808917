import { z } from "zod";
import { components as configurationComponents } from "../types/configuration";
import { components as patronComponents } from "../types/patron";

type BaseError = { info: string; code: number };
type AuthenticatePatronError = patronComponents["schemas"]["AuthenticatePatronV2Error"];
type GetPatronLoansError = patronComponents["schemas"]["GetPatronLoansError"];
type RenewPatronLoanError = patronComponents["schemas"]["RenewPatronLoanError"];
type GetPatronReservationsError = patronComponents["schemas"]["GetPatronReservationsError"];
type CreatePatronReservationError = patronComponents["schemas"]["CreatePatronReservationV2Error"];
type DeletePatronReservationError = patronComponents["schemas"]["DeletePatronReservationError"];
type ConfigurationApiError =
  | configurationComponents["schemas"]["GetSessionTokenError"]
  | configurationComponents["schemas"]["RefreshSessionTokenError"];
type InsertItemIntoPatronListError = patronComponents["schemas"]["InsertItemIntoPatronListError"];
type DeleteItemFromPatronListError = patronComponents["schemas"]["DeleteItemFromPatronListError"];
type GetPatronConsentsError = patronComponents["schemas"]["GetPatronConsentsError"];
type UpdatePatronConsentsError = patronComponents["schemas"]["UpdatePatronConsentsError"];

export class RediaPlatformError<ErrorType extends BaseError> extends Error {
  errors: ErrorType[];
  code: ErrorType["code"] | undefined;
  info: ErrorType["info"] | undefined;
  constructor(error: { errors: ErrorType[] }) {
    const errors = error.errors ?? [];
    super(errors.map(({ code, info }) => `[${code}] ${info}`).join(" "));
    this.errors = errors;
    this.code = errors?.[0]?.code;
    this.info = errors?.[0]?.info;
  }
}

export class RediaPlatformConfigurationApiError extends RediaPlatformError<ConfigurationApiError> {}

export type RediaPlatformAnyPatronApiError =
  | AuthenticatePatronError
  | CreatePatronReservationError
  | GetPatronReservationsError
  | DeletePatronReservationError
  | GetPatronLoansError
  | RenewPatronLoanError
  | InsertItemIntoPatronListError
  | DeleteItemFromPatronListError
  | GetPatronConsentsError
  | UpdatePatronConsentsError;

export class RediaPlatformPatronApiError extends RediaPlatformError<RediaPlatformAnyPatronApiError> {}

export const errorResponseSchema = z.object({
  errors: z.array(
    z.object({
      code: z.number(),
      info: z.string(),
    })
  ),
});

/**
 * Thrown when a request cannot be fulfilled because there is no user authenticated.
 */
export class Unauthenticated extends Error {
  constructor() {
    super("No user is currently authenticated");
  }
}
