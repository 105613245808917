import createClient from "openapi-fetch";
import { configurationPaths, libraryPaths, patronPaths, searchPaths } from "../types/index";
import { RediaPlatformSessionMiddleware } from "./RediaPlatformSessionMiddleware";
import {
  RediaPlatformAnyPatronApiError,
  RediaPlatformError,
  RediaPlatformPatronApiError,
  Unauthenticated,
} from "./errors";
import { getRediaPlatformBaseUrl } from "./getRediaPlatformBaseUrl";
import {
  PublicationHoldings,
  PatronReservation,
  Publication,
  RediaPlatform,
  RediaPlatformEnvironment,
  RediaPlatformProps,
  SessionConfiguration,
  UpdatePatronProfileOption,
  UserProfileUpdate,
  PatronListInsertItem,
  PatronConcents,
  EvaluatedPatronConsent,
} from "./interfaces";
import { standardQuerySerializer } from "./querySerializer";
import { SessionStore } from "./sessionStore";

const environmentClientIds: Record<RediaPlatformEnvironment, string> = {
  prod: "P7bfhht8L8cKfvaFQefFK6NqXAhNkAug7zBVcrWU",
  staging: "N6BGujeZdyDJPssm3nu6Twzj8PyjHqbbQcFJYz9Y",
  dev: "022d8699-d8bd-4810-8919-80ba90fde454",
};

type Props = RediaPlatformProps & { environment: RediaPlatformEnvironment };

export const createRediaPlatformClient = (props: Props): RediaPlatform => new RediaPlatformClient(props);

interface RediaPlatformApis {
  configuration: ReturnType<typeof createClient<configurationPaths>>;
  patron: ReturnType<typeof createClient<patronPaths>>;
  search: ReturnType<typeof createClient<searchPaths>>;
  library: ReturnType<typeof createClient<libraryPaths>>;
}

class RediaPlatformClient implements RediaPlatform {
  public readonly isMock = false;
  public environment: RediaPlatformEnvironment;
  private apis: RediaPlatformApis;
  private sessionMiddleware: RediaPlatformSessionMiddleware;
  private sessionStore: SessionStore;

  constructor(props: Props) {
    const { customerId, sessionStore, onSessionExpired, environment } = props;
    this.environment = environment;
    this.sessionStore = sessionStore;
    this.sessionMiddleware = new RediaPlatformSessionMiddleware({
      environment,
      sessionStore,
      clientId: environmentClientIds[environment],
      customerId,
      onSessionExpired,
    });
    const fetchWithSession = this.sessionMiddleware.getFetch();
    this.apis = {
      configuration: createClient<configurationPaths>({
        baseUrl: getRediaPlatformBaseUrl({ api: "configuration", environment }),
        fetch: fetchWithSession,
        querySerializer: standardQuerySerializer,
      }),
      patron: createClient<patronPaths>({
        baseUrl: getRediaPlatformBaseUrl({ api: "patron", environment }),
        fetch: fetchWithSession,
        querySerializer: standardQuerySerializer,
      }),
      search: createClient<searchPaths>({
        baseUrl: getRediaPlatformBaseUrl({ api: "search", environment }),
        fetch: fetchWithSession,
        querySerializer: standardQuerySerializer,
      }),
      library: createClient<libraryPaths>({
        baseUrl: getRediaPlatformBaseUrl({ api: "library", environment }),
        fetch: fetchWithSession,
        querySerializer: standardQuerySerializer,
      }),
    };
  }

  public async checkCredientials(username: string, password: string) {
    const { error } = await this.apis.patron.POST("/api/patron/authentication/v2", {
      body: {
        identifier: {
          value: username,
        },
        password: password,
      },
    });
    if (error) {
      throw new RediaPlatformPatronApiError(error);
    }
  }

  public async login(username: string, password: string) {
    // Start en fersk sesjon før innlogging slik at sesjonslengden samsvarer med
    // hvor lenge en har vært innlogget. Vi kan evt. droppe dette senere hvis vi
    // tar i bruk refreshToken.
    this.sessionMiddleware.clearSession();

    const { data, error } = await this.apis.patron.POST("/api/patron/authentication/v2", {
      body: {
        identifier: {
          value: username,
        },
        password: password,
      },
    });
    if (error) {
      throw new RediaPlatformPatronApiError(error);
    }

    const loginTime = new Date();
    const expiresStr = this.sessionStore.get()?.token?.expiresTime;
    const expiresTime = expiresStr ? new Date(expiresStr) : undefined;
    if (expiresTime) {
      const sessionTimeLeft = (expiresTime?.getTime() - loginTime.getTime()) / 1000;
      if (sessionTimeLeft < 1000) {
        console.error(
          `Got unusually short session: ${sessionTimeLeft} seconds. Session expires: ${expiresTime.toISOString()}. Login time: ${loginTime.toISOString()}`
        );
      } else {
        console.info(`Session time left: ${sessionTimeLeft} seconds`);
      }
    }
    // Store user and login time
    this.sessionStore.patch({ user: data.patron, loginTime: loginTime.toISOString() });
  }

  public async logout() {
    // Update user state *first*, to avoid race conditions where requests
    // based on the user session is starting while we're logging out.
    this.sessionStore.patch({ user: null, loginTime: null });
    const { error } = await this.apis.patron.GET("/api/patron/deauthenticate", {});
    if (error) {
      console.error("Failed to logout user from Redia Platform", error);
      // Her kan vi evt. la være å tømme den lokale sesjonen og la brukeren
      // prøve på nytt. Men risikoen for at det feiler på nytt er vel stor, og
      // da bli en sittende med en lokal sesjon uten mulighet til å logge ut. Så
      // kanskje tross alt beste å slette den lokale sesjonen. Sesjonen hos
      // Redia vil jo uansett ikke vare mer enn 30 minutter.
      this.sessionMiddleware.clearSession();
    }
  }

  public getUser() {
    return this.sessionMiddleware.getSession()?.user ?? undefined;
  }

  public async getConfiguration(): Promise<SessionConfiguration> {
    const { data, error } = await this.apis.configuration.GET("/api/session/configuration", {});
    if (error) throw new RediaPlatformError(error);
    return data.configuration;
  }

  private handlePatronError(errors: { errors: RediaPlatformAnyPatronApiError[] }) {
    return !this.getUser() ? new Unauthenticated() : new RediaPlatformPatronApiError(errors);
  }

  public async refreshUserProfile() {
    const { data } = await this.apis.patron.GET("/api/patron/information/v2", {});
    if (data) {
      // Check that we have not been logged out (or logged in as another user) since the request started
      if (data.patron.identifiers?.[0]?.value === this.getUser()?.identifiers?.[0]?.value) {
        this.sessionStore.patch({ user: data.patron });
        return data.patron;
      }
    }
    return undefined;
  }

  public async getReservations(): Promise<PatronReservation[]> {
    const { data, error } = await this.apis.patron.GET("/api/patron/reservation/v2", {});
    if (error) throw this.handlePatronError(error);
    return data.reservations;
  }

  public async deleteReservation(reservationId: string) {
    const { error } = await this.apis.patron.DELETE("/api/patron/reservation/{reservationId}", {
      params: {
        path: { reservationId },
      },
    });
    if (error) throw this.handlePatronError(error);
  }

  public async createReservation(reservationId: string, pickupBranchCode: string) {
    const { data, error } = await this.apis.patron.POST("/api/patron/reservation/v3", {
      body: {
        reserveId: reservationId,
        pickupBranchCode: pickupBranchCode,
      },
    });
    if (error) throw this.handlePatronError(error);
    return data.reservation;
  }

  public async getLoans() {
    const { data, error } = await this.apis.patron.GET("/api/patron/loan/v2", {});
    if (error) throw this.handlePatronError(error);
    return data.loans;
  }

  public async renewLoan(loanId: string) {
    const { data, error } = await this.apis.patron.PUT("/api/patron/loan/{loanId}/v2", {
      params: {
        path: { loanId },
      },
    });
    if (error) throw this.handlePatronError(error);
    return data.loan;
  }

  public async getBranches() {
    const { data, error } = await this.apis.library.GET("/api/branches/information", {
      params: {},
    });
    if (error) throw new RediaPlatformError(error);
    return data.branches;
  }

  public async getBranch(branchCode: string) {
    const { data, error } = await this.apis.library.GET("/api/branches/information", {
      params: { query: { branches: [branchCode] } },
    });
    if (error) throw new RediaPlatformError(error);
    return data.branches[branchCode];
  }

  public async getLoanHistory(before_timestamp: string, page?: number, size?: number) {
    const { data, error } = await this.apis.patron.GET("/api/patron/loan-history", {
      params: {
        query: {
          before_timestamp,
          page: page ?? 1,
          size: size ?? 50,
        },
      },
    });
    if (error) throw new RediaPlatformError(error);
    return data;
  }

  public async deleteLoanHistory(loanHistoryId: string) {
    const { error } = await this.apis.patron.DELETE("/api/patron/loan-history/{loanHistoryId}", {
      params: {
        path: { loanHistoryId },
      },
    });
    if (error) throw this.handlePatronError(error);
  }

  public async getLists() {
    const { data, error } = await this.apis.patron.GET("/api/patron/list");
    if (error) throw this.handlePatronError(error);
    return data;
  }

  public async getList(listId: string) {
    const { data, error } = await this.apis.patron.GET("/api/patron/list/{listId}", {
      params: {
        path: { listId },
      },
    });
    if (error) throw new RediaPlatformError(error);
    return data;
  }

  public async addItemToList(listId: string, item: PatronListInsertItem) {
    const { error } = await this.apis.patron.POST("/api/patron/list/{listId}", {
      params: {
        path: { listId },
      },
      body: {
        item: {
          publicationId: item.publicationId,
          workId: item.workId,
        },
      },
    });
    if (error) throw this.handlePatronError(error);
  }

  public async deleteItemFromList(listId: string, itemId: string) {
    const { error } = await this.apis.patron.DELETE("/api/patron/list/{listId}/item/{itemId}", {
      params: {
        path: { listId, itemId },
      },
    });
    if (error) throw this.handlePatronError(error);
  }

  public async createList(name: string) {
    const { data, error } = await this.apis.patron.POST("/api/patron/list", {
      body: {
        name,
      },
    });
    if (error) throw new RediaPlatformError(error);
    return data;
  }

  public async updateListName(listId: string, name: string) {
    const { error } = await this.apis.patron.PATCH("/api/patron/list/{listId}", {
      params: {
        path: { listId },
      },
      body: {
        name,
      },
    });
    if (error) throw this.handlePatronError(error);
  }

  public async deleteList(listId: string) {
    const { error } = await this.apis.patron.DELETE("/api/patron/list/{listId}", {
      params: {
        path: { listId },
      },
    });
    if (error) throw this.handlePatronError(error);
  }

  public async getLocations() {
    const { data, error } = await this.apis.library.GET("/api/locations");
    if (error) throw new RediaPlatformError(error);
    return data;
  }

  public async getPublications(publicationIds: string[]): Promise<Record<string, Publication | undefined>> {
    if (publicationIds.length === 0) return {};
    const { data, error } = await this.apis.search.GET("/api/publication/v2", {
      params: {
        query: {
          publication_ids: publicationIds,
        },
      },
    });
    if (error) throw new RediaPlatformError(error);
    return data.publications;
  }

  public async getHoldings(publicationIds: string[]): Promise<Record<string, PublicationHoldings | undefined>> {
    if (publicationIds.length === 0) return {};
    const { data, error } = await this.apis.library.GET("/api/holdings/v2", {
      params: {
        query: {
          publication_ids: publicationIds,
          show_to: ["public"],
        },
      },
    });
    if (error) throw new RediaPlatformError(error);
    return data.holdings;
  }

  public async updateUserProfile({ phoneNumbers, emails, pickupLocationBranchCode }: UserProfileUpdate) {
    const options: UpdatePatronProfileOption[] = [];
    const phones = [];
    if (phoneNumbers?.mobile)
      phones.push({
        isDefault: true,
        number: phoneNumbers.mobile,
        type: "mobile" as const,
      });
    if (phoneNumbers?.landline)
      phones.push({
        isDefault: true,
        number: phoneNumbers.landline,
        type: "landline" as const,
      });
    if (phones.length > 0) {
      options.push({
        phoneNumbers: phones,
        type: "phone_numbers_full_list",
      });
    }

    if (emails?.email) {
      options.push({
        emails: [
          {
            email: emails.email,
            isDefault: true,
          },
        ],
        type: "emails_full_list",
      });
    }

    if (pickupLocationBranchCode) {
      options.push({
        pickupLocationBranchCode,
        type: "pickup_location_branch_code",
      });
    }
    const { data, error } = await this.apis.patron.PATCH("/api/patron/information", {
      body: {
        options: options,
      },
    });
    if (error) throw new RediaPlatformError(error);
    return data;
  }

  public async getConsents(): Promise<PatronConcents> {
    const { data, error } = await this.apis.patron.GET("/api/patron/consents");
    if (error) throw this.handlePatronError(error);
    return data;
  }

  public async updateConsents(consents: EvaluatedPatronConsent[]): Promise<PatronConcents> {
    const { data, error } = await this.apis.patron.PATCH("/api/patron/consents", {
      body: {
        consents,
      },
    });
    if (error) throw this.handlePatronError(error);
    return data;
  }
}
